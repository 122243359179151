import { mapActions, mapGetters } from 'vuex';
import ProductCard from '@/components/cards/ProductCard/index.vue';
import _ from 'lodash';

export default {
  name: 'profile-favorites',
  components: {
    ProductCard
  },
  computed: {
    ...mapGetters({
      favourites: 'favorites/whichList'
    }),
    favoritesProducts() {
      return _.map(this.favourites, 'product.data');
    }
  },
  methods: {
    ...mapActions({})
  }
};
